import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import HeroHeader from "../../components/HeroHeader";
import MemberForms from "../../components/MemberForms";
import InstagramFeed from "../../components/InstagramFeed";
import Layout from "../../components/layout";

const memberFormsId = "member-forms";

const heroHeaderQuery = graphql`
  query {
    image: file(relativePath: { eq: "getting-started-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default function FEATMembers() {
  const headerImage = useStaticQuery(heroHeaderQuery);

  return (
    <Layout>
      <Seo
        title="FEAT Fitness Member Area"
        meta_title="FEAT Fitness Member Area"
        meta_desc="As a FEAT Member, this is where you can fill out any forms to let us know about any updates to your members."
        slug="feat-members"
      />
      <main>
        <HeroHeader
          title={"FEAT Fitness Members"}
          background_image={headerImage?.image}
          options={[
            {
              title: "Member Forms",
              to: `#${memberFormsId}`,
            },
          ]}
        />
        <MemberForms id={memberFormsId} />
        <InstagramFeed />
      </main>
    </Layout>
  );
}
