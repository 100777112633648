import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import Button from "../Button";
import Heading from "../Heading";

const MemberButton = (props) => (
  <Link to={`/feat-members/${props.to}`}>
    <Button className="bg-feat-blue text-white mt-4 w-full uppercase text-heading pb-4 text-lg h-60 hover:bg-feat-blue-700 group">
      <div className="flex flex-col align-text-bottom h-full">
        <img
          src={props.icon}
          alt={props.children}
          className="transition duration-200 ease-in-out w-2/3 mx-auto h-3/4 flex-grow transform hover:scale-110"
          fill="white"
        />
        {props.children}
      </div>
    </Button>
  </Link>
);

export default function MemberFormsPage({ id, className, ...props }) {
  return (
    <div id={id} className={classNames(className, "my-16")} {...props}>
      <div className="text-center mb-4">
        <h2 className="text-heading uppercase text-3xl sm:text-4xl text-feat-darkgray text-center mb-2 sm:mb-8">
          Member Forms
        </h2>
        <h3 className="text-base text-feat-darkgray-300">
          Select a form below to update anything on your membership.
        </h3>
      </div>
      <div className="text-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mx-16 my-4">
        <MemberButton to="freeze-membership" icon={"/img/icons/snowflake.svg"}>
          Freeze Membership
        </MemberButton>
        <MemberButton to="change-membership" icon={"/img/icons/change.svg"}>
          Change Membership
        </MemberButton>
        <MemberButton to="receipt-request" icon={"/img/icons/receipt.svg"}>
          Request Receipt
        </MemberButton>
        <MemberButton to="stop-payments" icon={"/img/icons/stop.svg"}>
          Stop Payments
        </MemberButton>
      </div>
    </div>
  );
}
